import { tabs } from "./static/others/navigationTabs";

export const isLocal: boolean = window.location.hostname === "localhost";
export const isDev: boolean =
  isLocal || window.location.hostname === "dev.my.socraft.ch";
export const isQa: boolean = window.location.hostname === "qa.my.socraft.ch";
export const isProd: boolean = window.location.hostname === "my.socraft.ch";

export const socraftApiBaseUrl = isLocal ? "http://localhost:8000/api" : `/api`;

export const displayedTabs = tabs.filter(
  (tab) => tab.production || isDev || isQa,
);

export const defaultImageUrl =
  "https://avatars.slack-edge.com/2023-02-01/4735569099092_250e4584903fddadc1ac_132.png";
